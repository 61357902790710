import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AppDispatch, RootState } from "../state/store";
import {
  jwtSelector,
  currentUserSelector,
  currentOrgSelector,
} from "../state/selectors/authSelector";
import Router from "./Router";
import Translator from "./Translator";
import { useEffect, useState } from "react";
import {
  setAuthToken,
  isTokenExpired,
  setAxiosResponseHandler,
  setAxiosRequestHandler,
} from "../util/auth";
import { getAuthFromLocalStorage } from "../util/localStorage";

import { getAllProjects } from "../../features/projects/state/projectsSlice";
import { getTeam } from "../../features/team/state/teamSlice";
import { setProfile } from "../../features/profile/state/profileSlice";
import { setAuth } from "../../features/auth/state/authSlice";
import { clearAlerts, setAlert } from "../../features/alert/state/alertsSlice";
import { fetchBuilds } from "../../features/builds/state/buildsSlice";
import { prettifyErrorMessage } from "../util/prettifyErrorMessage";
import { fetchProjectGroupsCompletion } from "../../features/enduserStats/state/enduserStatsSlice";
import {
  PROJECT_GROUP_LIST_COMPONENT_ID,
  PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID,
} from "../../features/projectGroups/components/ProjectGroupList";
import { ORGANIZATION_COMPONENT_ID } from "src/pages/Profile/Organization";
import apiUrls from "../../api";
import { fetchElevenlabsVoices, getAllMedia } from "src/features/gallery/state/gallerySlice";
import {
  fetchProjectGroupsWithProjects,
  getCurrentProjectGroupForSearch,
} from "src/features/projectGroups/state/projectGroupsSlice";
import {
  BUILDS_COMPONENT_ID,
  STATS_OVERVIEW_PROJECT_GROUPS_COMPONENT_ID,
} from "src/features/stats/components/StatsOverview/StatsOverview";
import { fetchMapContentKeysWithPldKeys } from "src/features/graph/state/graphLegacy";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import {
  fetchGptConversations,
  setActiveGptConversation,
} from "src/features/chatbot/state/chatbotSlice";
import { GPT_CONVERSATION_LOADER_COMPONENT_ID } from "src/features/chatbot/components/GptConversationLoader";
import { GENERATE_MEDIA_PANEL_COMPONENT } from "src/features/gallery/components/GenerateMediaPanel";

export const productionMode =
  process.env.NODE_ENV !== "development" && !process.env.REACT_APP_DEBUG;

export default function App() {
  const dispatch: AppDispatch = useDispatch();

  const projectsGroup = useSelector(getCurrentProjectGroupForSearch);

  // Add a state to track Axios readiness
  const [axiosReady, setAxiosReady] = useState(false);

  const { jwt, user, organization, proabonoWixarAiAccess } = useSelector((state: RootState) => {
    return {
      jwt: jwtSelector(state),
      user: currentUserSelector(state),
      organization: currentOrgSelector(state),
      proabonoWixarAiAccess: getSubscriptionFeature(state, "wixar-ai-access"),
    };
  });
  const isAllowedToUseWixarAi = proabonoWixarAiAccess?.IsEnabled;

  useEffect(() => {
    // Set JWT globally
    if (jwt && !isTokenExpired(jwt)) {
      dispatch(clearAlerts());
      setAuthToken(jwt);

      // Initial dispatch of user & organization
      const currentUser = JSON.parse(localStorage.getItem("wixar.profile.user") as string);
      const organization = JSON.parse(localStorage.getItem("wixar.profile.organization") as string);
      dispatch(setProfile({ currentUser, organization }));
      if (currentUser?.active_gpt_conversation) {
        dispatch(setActiveGptConversation(currentUser.active_gpt_conversation));
      }
      setAxiosReady(true); // Indicate that Axios is ready to make requests
    } else {
      setAxiosReady(false); // Ensure Axios is not ready if the token isn't valid
    }
  }, [jwt]);

  useEffect(() => {
    // Let's prefetch medias so they can be used in Profile views
    // but firstly lets check if axios is ready
    if (axiosReady) {
      const orgString = window.localStorage.getItem("wixar.profile.organization") as string;
      const org = orgString ? JSON.parse(orgString) : 0;
      dispatch(getAllMedia({ orgId: Number(org.id) }));
      if (projectsGroup.length >= 2) {
        dispatch(
          fetchProjectGroupsWithProjects({
            componentId: STATS_OVERVIEW_PROJECT_GROUPS_COMPONENT_ID,
          }),
        );
      } else {
        dispatch(
          fetchProjectGroupsWithProjects({
            limit: 2,
            componentId: PROJECT_GROUP_LIST_WITH_LIMIT_COMPONENT_ID,
          }),
        ).then(() => {
          dispatch(
            fetchProjectGroupsWithProjects({
              componentId: STATS_OVERVIEW_PROJECT_GROUPS_COMPONENT_ID,
            }),
          );
        });
      }
      dispatch(fetchMapContentKeysWithPldKeys());
    }
  }, [axiosReady]);

  useEffect(() => {
    if (isAllowedToUseWixarAi) {
      dispatch(fetchGptConversations({ componentId: GPT_CONVERSATION_LOADER_COMPONENT_ID }));
    }
  }, [isAllowedToUseWixarAi]);

  // Fetch project list with legacy project slice
  useEffect(() => {
    if (user) {
      if (user.role.name === "Creator") {
        dispatch(getAllProjects({ componentId: "projectListComponent" }));
        dispatch(fetchBuilds({ componentId: BUILDS_COMPONENT_ID }));
        dispatch(fetchElevenlabsVoices({ componentId: GENERATE_MEDIA_PANEL_COMPONENT }));
      }
      if (user.role.name === "Enduser") {
        dispatch(
          fetchProjectGroupsCompletion({ limit: 3, componentId: PROJECT_GROUP_LIST_COMPONENT_ID }),
        );
      }
    }
  }, [user]);

  useEffect(() => {
    if (organization && organization.id) {
      dispatch(getTeam({ orgId: organization.id, componentId: ORGANIZATION_COMPONENT_ID }));
    }
  }, [organization]);

  const authFromLocalStorage = getAuthFromLocalStorage();
  if (
    authFromLocalStorage.user &&
    authFromLocalStorage.organization &&
    authFromLocalStorage.jwt &&
    authFromLocalStorage.jwt !== null &&
    !jwt &&
    !isTokenExpired(authFromLocalStorage.jwt)
  ) {
    dispatch(
      setAuth({
        jwt: authFromLocalStorage.jwt,
        user: authFromLocalStorage.user,
        organization: authFromLocalStorage.organization,
      }),
    );
  }

  useEffect(() => {
    console.log("Setting the axios response handler...");

    // Set axios interceptor to use along Alert component
    setAxiosResponseHandler((response: any) => {
      // Error localization & prettification is happening in src/common/util/prettifyErrorMessage.ts

      if (response?.data) {
        const parsedAxiosError = JSON.stringify(response?.data);
        const prettyErrorMessage = prettifyErrorMessage(parsedAxiosError);

        if (response.request.responseURL !== apiUrls.auth.checkPwd) {
          dispatch(
            setAlert({
              msg: prettyErrorMessage.msg,
              subMsg: prettyErrorMessage.subMsg,
              type: "warning",
              isOpen: true,
              customTimeoutInMs: 10000,
            }),
          );
        }

        return response;
      }
    });
    setAxiosRequestHandler();
  }, []);

  if (productionMode) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.warn = function () {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.error = function () {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.time = function () {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.timeEnd = function () {};
  } else {
    // exceptions for development mode goes here
    document.body.className = "devmode-body";
    const originalConsoleError = console.error.bind(console);
    console.error = function (...args) {
      const modifiedArgs = args.map((arg) => {
        if (typeof arg === "string") {
          return arg.replace(/webpack-internal:\/\/\//g, "");
        }
        return arg;
      });
      originalConsoleError(...modifiedArgs);
    };
  }

  return (
    <Translator>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Translator>
  );
}
